@import '../principal/variavel.scss';
.produto-principal{
    width: 100%;
    height: 700px;
}
#barra{
	position: relative;
	left: 120px;
	color: white;
}
#barra1{
    position: relative;
	left: 260px;
    bottom: 33px;
	color: white;
}
.imgp {
    position: relative;
    width: 70% ;
    height: 680px ;
    left: 80px;
    top: 10px  ;
}
.carousel div{
    
}

.btn-slider{
    position: relative;
    display: flex;
    flex-direction: column;
    right: 100px;
    bottom:670px;
    margin-bottom: 5px;
    border: none;
    img{
        position: relative;
        height: 150px ;
        width: 150px ;
        border-radius: 10px;
    }
    &:hover{
        cursor: pointer;
    }
}

 
.prod-h1{
    position: relative;
    left: 310px;
    bottom: 690px;
    font-weight: 700;
	font-size: 35px;
	font-family: "Segoe UI";
    color: rgb(131, 128, 128);
}
#txt-cf5{
    position: absolute;
    width: 36%;
    bottom: 30px;
    left: 750px;
    font-size: 20px;
    font-family: "Segoe UI";
    font-weight: 500;
}
label{
    position: relative;
    left: 300px;
    top: -350px;
    font-size: 20px;
}
select{
    position: relative;
    left: 330px;
    top: -350px;
    width: 330px;
    height: 40px;
    border-radius: 10px;
    font-size: 20px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.whatsapp{
	position: relative;
	height: 70px;
	width: 250px;
	left: 900px;
	top:-320px;
	border-radius: 10px;
	background-color: gray;
	box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
 &:hover{
	
	background-color: $color-secondary;
	box-shadow: none;
    border: 1px solid black;
}
}
.p-what{
	position: relative;
 bottom: 60px;
 left: 30px;
 color: white;
 font-family: "Segoe UI";
}
.what{
    
    text-decoration: none;
    color: gray;
    font-size: 25px;
    font-family: "Segoe UI";
}
.what img{
	position: relative;
	top: 12px;
	left: -90px;
    width: 45px;
    height: 45px;
	
}
.imgEspecial{
    position: relative;
    border: 1px solid lightgray;
    width: 700px;
    right: 200px;
}
@media (min-width:270px) and  (max-width: 550px){
    html{
        overflow-x: hidden !important;
    }
    button{
        width: 82px;
    }
        .imgp{
    left: 5px;
    height: 500px;
    top: 31px;
    }
    .carousel div{
        width: 380px;
        height: 500px ;
    }
    .btn-slider{
      
        display: flex;
        max-width:82px;
        right: -1px;
        bottom:-50px;
        flex-direction: row-reverse ;
        margin-left: 10px;
        img{
            width: 70px !important;
            height: 70px !important;
        }
    }
    .btn-2{
        left: 90px;
        bottom: 27px;
    }
    .btn-3{
        left: 180px;
        bottom: 105px;
    }
    .btn-4{
        left: 270px;
        bottom: 180px;
    }
    
    .btn-6{
        left: -20px;

    }
    .btn-7{
        left: 55px;
        bottom: 27px;
    }
    .btn-8{
        left: 130px;
        bottom: 105px;
    }
    .btn-9{
        left: 205px;
        bottom: 182px;
    }
    .btn-10{
        left: 280px;
        bottom: 259px;
    }
    .prod-h1{
        left: 10px;
        font-size: 20px;
        bottom:-170px;
    }
    #txt-cf5{
        position: relative;
        left: 30px;
        bottom: -150px;
        width: 330px;
    }
    label{
        left: -30px;
        top: 150px;
    }
    select{
        left: -10px;
        width: 120px;
        height: 30px;
        top: 150px;
    }
    .whatsapp{
        width: 200px;
     height: 50px;
     left: 100px;
     top:190px;
    }
    .p-what{
        left: 20px;
        font-size: 18px;
        bottom: 45px;
    }
    .what img{
        width: 30px;
        height: 30px;
        left: -75px;
        top: 8px;
    }
    .imgEspecial{
        width: 100%;
        right: 0px;
        border: none;
    }
    }