@import './variavel.scss';

.best-selling {
	width: 100%;
	height: 550px;
	background: whitesmoke;
	border: 1px solid lightgray;
}
.img-best-selling{
	position: relative;
	width: 500px;
	margin-left: 90px;
	height: 420px;
	bottom: 10%;
}
.titleP-best-selling{
	
	margin-top: 30px;
	margin-bottom: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	left: 50%;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
}
.title-best-selling{
 position: relative;
  left: 22%;
  bottom: 80%;
  font-weight: 700;
}
.text-best-selling{
	position: relative;
	width:720px;
	height: 300px;
	left: 45%;
	bottom: 82%;
	font-weight: 500;
	font-size: 20px;
}
.btn-best-selling{
	box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
	position: relative;
	left: 22%;
	bottom: 83%;
	margin: auto; 
	padding: 10px;
	width: 25%;
	text-transform: capitalize;
	border: none;
	border-radius: 5px;
	transition: 0.5s;
	cursor: pointer;
	font-size: 20px;
	background:$color-primary;
	color: white !important;
	text-decoration: none;
		span:hover {
			position: relative;
			color: white !important;
			text-decoration: none;
		  }
		  &:hover{
			bottom: 88%;
			outline: none;
			color: white !important;
			box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
			&:active {
				outline: none;
				box-shadow: none;
				border: 1px solid black;
			  }
}
  }
  @media (min-width:270px) and  (max-width: 550px){
  .titleP-best-selling{
 font-size: 17px;
  }
.img-best-selling{
	right: 75px;
	width: 150%;
}
.title-best-selling{
	font-size: 16px;
	left: 0%;
	bottom: 93%;
}
.text-best-selling{
	left: 8%;
	width: 340px;
	color: white;
	bottom: 93%;
	font-weight: 500;
	font-size: 17px;
	text-align: justify;
}
.btn-best-selling{
	bottom: 82%;
	left: 1%;
	&:hover{
		bottom: 85%;
	}
}

  }