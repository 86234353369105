@import '.././principal/variavel.scss';
.produto-infor{
	position: relative;
	height: 600px;
	width: 100%;
	top: 240px;
}
.infad{
	position: relative;
	left: -430px;
	bottom: 39px;
	text-decoration: none;
	color: GRAY;
	font-family: "Segoe UI";
	font-weight: 700;
	border: none;
	background: white;
}
.linha1{
	top: 82px;
left: 50px;
}
.des{
	position: relative;
	left: -600px;
	top: -17px;
	text-decoration: none;
	color: gray;
	font-family: "Segoe UI";
	font-weight: 700;
	border: none;
	background: white;
}
.linha2{
	display: none;
	position: relative;
	width: 90px;
	background-color: $color-secondary;
	height: 6px;
	left: 20px;
	bottom: 20px;
}
.linha3{
	display: none; 
	position: relative;
	width: 205px;
	background-color: $color-secondary;
	height: 6px;
	left: 140px;
	bottom: 45PX;
}

.pri1{
display: block;
&:hover .linha2 {
    display: block;
  }
 
  &:hover .des{
	color: black;
}
}
.pri2:hover {
	display: block;
	&:hover .infad{
        color: black;
    }
    &:hover .linha3{
        display: block;
      }
}
.descricao1{
    position: relative;
	margin-left: 40px;
	font-size: 20px;
	font-family: "Segoe UI";
    right: 370px;
}
.informacao{
	position: relative;
	margin-left: 40px;
	font-size: 20px;
	font-family: "Segoe UI";
	right: 600px;
}
.hidden{
	display: none;
}
li {
	list-style: none;
  }
  .linha4{
	position: relative;
	width: 97%;
	background-color: whitesmoke;
	height: 2px;
	bottom: 17PX;
}
.linha5{
	position: relative;
	width: 97%;
	background-color: whitesmoke;
	height: 2px;
	bottom: -27PX;
}
.linha6{
	position: relative;
	width: 97%;
	background-color: whitesmoke;
	height: 2px;
	bottom: 35PX;
	
}
#compri{
	position: relative;
	left: 60px;
	font-size: 20px;
	font-family: "Segoe UI";
	font-weight: 700;
	top: 35px;
}
#cm{
	position: relative;
	left: 1000px;
	font-size: 20px;
	font-family: "Segoe UI";
	font-weight: 700;
	bottom: 13px;
}
@media (min-width:270px) and  (max-width: 550px){
	body{
		overflow-x: hidden;
	}
	html{
		overflow-x: hidden !important;
	}
	.produto-infor{
		top: 500px;
		height: 200px;
	}
    .linha1{
		left: -40px;
		width: 80%;
	}
	.linha3{
		width:110px;
		left: 90px;
		top: 58px;
		height: 6px;
	}
	.infad{
		left: -50px;
		top: 60px;
	}
	.des{
		left: -150px;
		top: 82px;
	}
	.linha2{
		left: -7px;
		top: 80px;
	}
	.descricao1{
		right: 10px;
		top: 50px;
	}
	.informacao{
		right: 180px;
		top: 20px;
	}
	#cm{
		left: 200px;
	}

}