

.depoimentos{
	margin-top: 70px;
	width: 100%;
	height: 500px;
	
  }
  .title-depoimentos{
	 display: flex;
	 flex-direction: row;
	position: relative;
	font-size: 35px;
	height: 60px;
	left: 17%;
	width: 100%;
	margin-right: 20%;
  }
  .container-dep{
	display: flex;
	align-items: center;
	position: relative;
	top: -20%;
	width: 100%;
	height: 600px;
	flex-direction: row;
	left: 2%;
  }
  .video{
	width: 390px;
	height: 340px;
	padding: 10px;
	box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.2), 5px 0px 5px rgba(0, 0, 0, 0.2), 0px 5px 5px rgba(0, 0, 0, 0.2);
	  &:hover{
		transform: translateY(-10px);
		box-shadow: -10px 10px 5px rgba(0, 0, 0, 0.2);
	  }
	  video{
		margin-left: 2px;
		width: 333px;
		height: 187px;
	 }
  }
  .video2{
	margin-right: 30px;
	margin-left: 30px;
  }
  @media (min-width:270px) and  (max-width: 550px){
 .depoimentos{
	position: relative;
	height: 1200px;
	top: 120px;
	
 }
	.title-depoimentos{
	font-size: 16px;
	left: 16%;
	width: 70%;
	bottom: 180px;
 }
 .container-dep{
	display: flex;
	flex-direction: column;
	left: 5px;
	top: -170px;
 }
 .video{
	width: 300px;
	margin-bottom: 20px;
 }
 video{
	width:300px !important;
 }
}