

.caracteristicas-empresa{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1%;
	margin-bottom: 2%;
    bottom: 3%;
	height: 500px;
	div{
        position: relative;
        width: 100%;
        height: 400PX;
        
    }
	
}

.imgC {
	width: 60px;
	height: 60px;
	position: relative;
	left: 1%;
	border-radius: 30%;
	top: 10px;
}

.divC{
	width: 230px;
	height: 230px;
	border: 1px solid  lightgrey;
	margin-left: 20px;
	border-radius: 20px;
}
#titleC{
	position: relative;
	left: 10px !important;
	top: -10px;
  font-size: 30px;
}
#titleA{
	position: relative;
	top: -10px;
	left: 4px;
	font-size: 30px;
}
#pc{
	position: relative;
	width: 80%;
	margin-left: 10%;
	top: 10px;
	font-size: 28px;
}
@media (min-width:270px) and  (max-width: 550px){
	.caracteristicas-empresa{
		flex-direction: column;
	}
	.divC{
		top: 280px;
	    margin-right: 14px !important;
	   margin-bottom: 15px;
	}
	#pc{
		top: -50px;
	width: 320px;
	}
}