.header-princip {
  width: 100%;
  position: relative;
  height: 90px;
  top: 110px;
}

.divulgacao {
  position: relative;
  width: 100%;
  height: 40px;
  background: green;
  bottom: 150%;
}

#pd {
  position: relative;
  top: 12px;
  color: green;
  margin-left: 20px;
  font-weight: 700;
}

.icon-fac {
  position: relative;
  width: 27px;
  height: 27px;
  left: 48%;
  bottom: 30px;
  border: white 2px solid;
  border-radius: 15px;
  background: white;
  background-color: #3b5998;
}
.icon-fac:hover {
  width: 30px;
  height: 30px;
}

.icon-tik {
  position: absolute;
  width: 27px;
  height: 27px;
  left: 89%;
  bottom: 2px;
  border: white 2px solid;
  border-radius: 15px;
  background: white;
}
.icon-tik:hover {
  width: 30px;
  height: 30px;
}

.icon-ins {
  position: absolute;
  width: 27px;
  height: 27px;
  left: 93%;
  bottom: 2px;
  border: white 2px solid;
  border-radius: 15px;
  background: white;
  background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D);
}
.icon-ins:hover {
  width: 30px;
  height: 30px;
}

/* header */
#header {
  margin-top: -1px;
  position: relative;
  width: 100%;
  height: 30px;
  background: white;
  height: 60px;
  bottom: 40px;
}

#imgIcon {
  width: 30px;
  height: 30px;
  margin-left: 2%;
  border-radius: none;
  position: absolute;
}

/*Pesquisa */
#Hpes img {
  position: relative;
  left: 32%;
  width: 25px;
  height: 25px;
  top: -72px;
}

#HprodF {
  position: relative;
  left: 32%;
  font-weight: 700;
  font-size: 15px;
  top: -62px;
  color: black;
}

#HprodP {
  position: relative;
  left: 32%;
  font-weight: 700;
  font-size: 15px;
  top: -80px;
  color: black;
}

#Hprod {
  position: relative;
  left: 31%;
  font-weight: 700;
  font-size: 15px;
  top: -80px;
  color: black;
}

#Home {
  position: relative;
  left: 30%;
  font-weight: 700;
  font-size: 15px;
  top: -80px;
  color: black;
}

#IconEmpresa {
  width: 180px;
  height: 60px;
  margin-left: 3%;
  top: -80px;
  left: -25px;
  position: absolute;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.pesq {
  z-index: 999;
  display: none;
  position: relative;
  left: 72%;
  width: 350px;
  top: -50px;
  height: 70px;
  background: white;
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
}
.pesq .img-pesq {
  width: 30px;
  height: 36px;
}
.pesq .btn-pesq {
  width: 45px;
  height: 44px;
  position: relative;
  top: 15px;
  left: 11px;
  border: 3px solid black;
  background: whitesmoke;
}
.pesq .ipt-pesq {
  position: relative;
  width: 270px;
  left: 15px;
  height: 40px;
  top: 3px;
  border: 1px solid gray;
}
.pesq:active {
  border: none;
}

.miniproduto {
  height: 100%;
}

.c {
  z-index: 990;
  display: none;
  height: 50px;
  position: relative;
  left: 72%;
  width: 350px;
  bottom: 37%;
  background: white;
}
.c img {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  left: 30px;
}
.c a {
  position: absolute;
  top: 10px;
  left: 90px;
  color: black;
  font-family: "Segoe UI";
  text-decoration: none;
}

.miniproduto .c .b strong {
  font-weight: bold;
}
.miniproduto a:hover {
  text-decoration: none;
  color: black;
}

@media (min-width: 270px) and (max-width: 550px) {
  html {
    overflow-x: hidden;
  }
  .header {
    height: 110px;
  }
  .divulgacao {
    height: 70px;
  }
  #pd {
    width: 200px;
    font-size: 10px;
    right: 20px;
    top: 20px;
  }
  .icon-fac {
    left: 20%;
    bottom: 22px;
  }
  .icon-tik {
    left: 76%;
    bottom: 10px;
  }
  .icon-ins {
    left: 85%;
    bottom: 10px;
  }
  #IconEmpresa {
    width: 190px;
    height: 130px;
    left: -3px;
    bottom: 15px;
    z-index: 9968;
  }
  #Home {
    left: 43%;
    top: -85px;
    font-size: 15px;
    z-index: 9999;
  }
  #Hprod {
    left: 31%;
    top: -61px;
    font-size: 15px;
    z-index: 9997;
  }
  #HprodP {
    left: -5%;
    top: -34px;
    font-size: 15px;
    z-index: 9996;
  }
  .slide {
    bottom: -40px !important;
  }
  #HprodF {
    left: 1%;
    top: -30px;
    z-index: 9999;
  }
  #HprodF img {
    width: 50px;
    height: 50px;
  }
  #Hpes img {
    left: 20%;
    top: -40px;
    width: 30px;
    height: 30px;
  }
  .pesq {
    top: -30px;
    width: 270px;
    left: 20%;
  }
  .ipt-pesq {
    width: 200px !important;
    left: 10px !important;
    top: -2px !important;
  }
  .btn-pesq {
    left: 2px !important;
    top: 12px !important;
  }
  .c {
    width: 270px;
    left: 20%;
    height: 70px;
    border: 1px solid lightgray;
    bottom: 19%;
  }
  .c img {
    width: 35px;
    height: 35px;
    left: 10px;
    top: 10px;
  }
  .c a {
    left: 60px;
  }
}
.mySlides {
  position: relative;
  top: 20px;
  margin: auto;
  width: 100%;
  left: 1px;
}
.mySlides:hover {
  cursor: pointer;
}

.carousel-inner div {
  width: 100%;
}

@media (min-width: 270px) and (max-width: 550px) {
  .mySlides {
    top: 80px;
    height: 300px;
  }
}
.imgProduct {
  width: 410px;
  height: 390px;
}

.card-btn {
  width: 250px;
  height: 50px;
  top: 130px;
}

.flicking-panel {
  width: 250px;
  height: 450px;
  margin-left: 10px;
}
.flicking-panel div img {
  width: 250px;
  height: 250px;
}

.container {
  position: relative;
  top: 10px;
  margin-bottom: 100px;
  left: 15%;
  width: 100%;
  height: 400px;
}

.slick-dots {
  position: relative;
  top: 500px;
}

.title-special {
  width: 250px;
  height: 250px;
  border: 1px solid black;
}

.btn-more-products {
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
  padding: 10px;
  width: 150px;
  text-transform: capitalize;
  border: none;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  font-size: 20px;
  background: green;
  color: white;
  text-decoration: none;
  position: relative;
  bottom: 50px;
  left: 30px;
}
.btn-more-products:hover {
  bottom: 60px;
  font-size: 25px;
  outline: none;
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
}
.btn-more-products:active {
  box-shadow: none;
  border: 1px solid black;
}

@media (min-width: 270px) and (max-width: 750px) {
  .flicking-panel {
    height: 500px;
  }
  .imgProduct {
    width: 370px;
    height: 470px;
    position: relative;
    right: 50px;
  }
  .btn-more-products {
    left: 0px;
  }
}
.best-selling {
  width: 100%;
  height: 550px;
  background: whitesmoke;
  border: 1px solid lightgray;
}

.img-best-selling {
  position: relative;
  width: 500px;
  margin-left: 90px;
  height: 420px;
  bottom: 10%;
}

.titleP-best-selling {
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  left: 50%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.title-best-selling {
  position: relative;
  left: 22%;
  bottom: 80%;
  font-weight: 700;
}

.text-best-selling {
  position: relative;
  width: 720px;
  height: 300px;
  left: 45%;
  bottom: 82%;
  font-weight: 500;
  font-size: 20px;
}

.btn-best-selling {
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
  position: relative;
  left: 22%;
  bottom: 83%;
  margin: auto;
  padding: 10px;
  width: 25%;
  text-transform: capitalize;
  border: none;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  font-size: 20px;
  background: green;
  color: white !important;
  text-decoration: none;
}
.btn-best-selling span:hover {
  position: relative;
  color: white !important;
  text-decoration: none;
}
.btn-best-selling:hover {
  bottom: 88%;
  outline: none;
  color: white !important;
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
}
.btn-best-selling:hover:active {
  outline: none;
  box-shadow: none;
  border: 1px solid black;
}

@media (min-width: 270px) and (max-width: 550px) {
  .titleP-best-selling {
    font-size: 17px;
  }
  .img-best-selling {
    right: 75px;
    width: 150%;
  }
  .title-best-selling {
    font-size: 16px;
    left: 0%;
    bottom: 93%;
  }
  .text-best-selling {
    left: 8%;
    width: 340px;
    color: white;
    bottom: 93%;
    font-weight: 500;
    font-size: 17px;
    text-align: justify;
  }
  .btn-best-selling {
    bottom: 82%;
    left: 1%;
  }
  .btn-best-selling:hover {
    bottom: 85%;
  }
}
.depoimentos {
  margin-top: 70px;
  width: 100%;
  height: 500px;
}

.title-depoimentos {
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 35px;
  height: 60px;
  left: 17%;
  width: 100%;
  margin-right: 20%;
}

.container-dep {
  display: flex;
  align-items: center;
  position: relative;
  top: -20%;
  width: 100%;
  height: 600px;
  flex-direction: row;
  left: 2%;
}

.video {
  width: 390px;
  height: 340px;
  padding: 10px;
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.2), 5px 0px 5px rgba(0, 0, 0, 0.2), 0px 5px 5px rgba(0, 0, 0, 0.2);
}
.video:hover {
  transform: translateY(-10px);
  box-shadow: -10px 10px 5px rgba(0, 0, 0, 0.2);
}
.video video {
  margin-left: 2px;
  width: 333px;
  height: 187px;
}

.video2 {
  margin-right: 30px;
  margin-left: 30px;
}

@media (min-width: 270px) and (max-width: 550px) {
  .depoimentos {
    position: relative;
    height: 1200px;
    top: 120px;
  }
  .title-depoimentos {
    font-size: 16px;
    left: 16%;
    width: 70%;
    bottom: 180px;
  }
  .container-dep {
    display: flex;
    flex-direction: column;
    left: 5px;
    top: -170px;
  }
  .video {
    width: 300px;
    margin-bottom: 20px;
  }
  video {
    width: 300px !important;
  }
}
.caracteristicas-empresa {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1%;
  margin-bottom: 2%;
  bottom: 3%;
  height: 500px;
}
.caracteristicas-empresa div {
  position: relative;
  width: 100%;
  height: 400PX;
}

.imgC {
  width: 60px;
  height: 60px;
  position: relative;
  left: 1%;
  border-radius: 30%;
  top: 10px;
}

.divC {
  width: 230px;
  height: 230px;
  border: 1px solid lightgrey;
  margin-left: 20px;
  border-radius: 20px;
}

#titleC {
  position: relative;
  left: 10px !important;
  top: -10px;
  font-size: 30px;
}

#titleA {
  position: relative;
  top: -10px;
  left: 4px;
  font-size: 30px;
}

#pc {
  position: relative;
  width: 80%;
  margin-left: 10%;
  top: 10px;
  font-size: 28px;
}

@media (min-width: 270px) and (max-width: 550px) {
  .caracteristicas-empresa {
    flex-direction: column;
  }
  .divC {
    top: 280px;
    margin-right: 14px !important;
    margin-bottom: 15px;
  }
  #pc {
    top: -50px;
    width: 320px;
  }
}
.sobrenos {
  position: relative;
  top: 15px;
  display: flex;
  flex-direction: column;
  background: black;
  height: 520px;
  width: 100% !important;
}

.titleS {
  color: white;
}

.btn-fim {
  position: relative;
  left: 80px;
  bottom: 60px;
  font-size: 20px;
  color: white;
  border: none;
  background: black;
}

.imgs-fim {
  position: relative;
  top: 80%;
  width: 60px;
  height: 60px;
}

.icons-fim {
  width: 20px;
  height: 20px;
}

.fim-one {
  position: relative;
  width: 300px;
  height: 100px;
  left: 55%;
  bottom: 570px;
}

.fim-two {
  position: relative;
  width: 300px;
  height: 100px;
  left: 55%;
  bottom: 550px;
}

.fim-three {
  position: relative;
  width: 300px;
  height: 100px;
  left: 55%;
  bottom: 530px;
}

.fim-four {
  position: relative;
  width: 300px;
  height: 100px;
  left: 55%;
  bottom: 510px;
}

.gvtnine {
  position: absolute;
  left: 60%;
  width: 100%;
  top: 100%;
  color: white;
  font-family: "Segoe UI";
  font-weight: 700;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.gvtseven {
  position: absolute;
  left: 60%;
  width: 100%;
  top: 100%;
  color: white;
  font-family: "Segoe UI";
  font-weight: 700;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.gvtthree {
  position: absolute;
  left: 60%;
  width: 100%;
  top: 100%;
  color: white;
  font-family: "Segoe UI";
  font-weight: 700;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.of-btn {
  position: absolute;
  left: 60%;
  width: 100%;
  top: 100%;
  color: white;
  font-family: "Segoe UI";
  font-weight: 700;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.titleS2 {
  position: relative;
  height: 50px;
  color: white;
  font-size: 20px;
  left: 25%;
  bottom: 530px;
  font-size: 25px;
}

.titleS1 {
  position: relative;
  height: 50px;
  color: white;
  font-size: 20px;
  top: 40px;
  right: 32%;
  margin-left: 22%;
  font-size: 25px;
}

.pS {
  margin-top: 70px;
  position: relative;
  width: 50%;
  margin-left: 20px;
  color: white;
  font-size: 20px;
  text-align: justify;
}

.icon-fim {
  position: relative;
  max-width: 32px;
  max-height: 32px;
}

.fac {
  left: 40px;
  background: white;
  border: 1px solid white;
  border-radius: 100px;
  top: 8px;
  background-color: #3b5998;
}

.ins {
  left: 100px;
  bottom: 28px;
  background-color: white;
  border-radius: 100px;
  background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D);
  border: 1px solid white;
}

.tik {
  left: 160px;
  bottom: 65px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid white;
}

.iconS {
  position: relative;
  width: 40px;
  height: 40px;
}

@media (min-width: 270px) and (max-width: 550px) {
  .sobrenos {
    top: 650px;
    height: 1100px;
  }
  .titleS {
    left: -40px;
    top: -10px;
  }
  .pS {
    width: 330px;
    bottom: 100px;
    text-align: justify;
    left: 10px;
  }
  .iconS {
    bottom: 120px;
    left: 60px;
  }
  .ins {
    bottom: 28px;
  }
  .tik {
    bottom: 65px;
  }
  .titleS1 {
    left: -50px;
  }
  .titleS2 {
    position: relative;
    top: -200px;
    left: -5px;
  }
  .fim-one {
    top: -230px;
    margin-bottom: 20px;
    left: -25%;
  }
  .fim-two {
    top: -230px;
    margin-bottom: 20px;
    left: -25%;
  }
  .fim-three {
    top: -230px;
    margin-bottom: 20px;
    left: -25%;
  }
  .fim-four {
    top: -230px;
    margin-bottom: 20px;
    left: -25%;
  }
}
.rodape {
  position: relative;
  height: 100px;
  background: green;
  bottom: 0px;
  margin-bottom: 0%;
}
.rodape .p-rod {
  position: relative;
  top: 40%;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

@media (min-width: 270px) and (max-width: 550px) {
  .rodape {
    top: 620px;
  }
}
.whatsNovo {
  position: fixed;
  left: 90%;
  bottom: 100px;
  width: 90px;
  height: 90px;
  border-radius: 60%;
}

@media (min-width: 270px) and (max-width: 550px) {
  .whatsNovo {
    width: 50px;
    height: 50px;
    left: 80%;
    top: 56%;
    z-index: 99999;
  }
}
.catalogo {
  z-index: 10;
  position: relative;
  width: 100%;
  height: 100px;
  background: green;
  top: 10px;
}

.cat-ini {
  position: relative;
  color: white;
  margin-left: 40px;
  top: 30px;
  font-weight: 700;
  font-size: 20px;
  font-family: "Segoe UI";
  text-decoration: none;
  right: 400px;
}

.cat-cat {
  position: relative;
  color: white;
  margin-left: 80px;
  top: 30px;
  left: -440px;
  font-weight: 700;
  font-size: 20px;
  font-family: "Segoe UI";
  text-decoration: none;
}

@media (min-width: 270px) and (max-width: 550px) {
  .catalogo {
    top: 90px;
  }
  .cat-ini {
    right: 70px;
    font-size: 13px;
    top: 40px;
  }
  .cat-cat {
    left: -140px;
    font-size: 13px;
    top: 40px;
  }
}
.sidebar {
  height: 100px;
  width: 40%;
  position: absolute;
  top: 40%;
}

.h2-cat {
  position: relative;
  color: black;
  left: -24%;
  top: -1%;
  font-weight: 700;
  font-size: 20px;
  font-family: "Segoe UI";
  text-decoration: none;
}

.catpro {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 30px;
  width: 230px;
  margin-top: 10px;
  top: 2%;
  text-decoration: none;
  color: #228B22;
  font-size: 20px;
  font-family: "Segoe UI";
  font-weight: 700;
}
.catpro:hover {
  color: green;
  font-size: 25px;
  width: 300px;
  top: -1%;
}

.linha1 {
  position: absolute;
  width: 40%;
  height: 2px;
  background-color: lightgray;
  top: 17%;
  left: 6%;
  margin-top: 10px;
}

@media (min-width: 270px) and (max-width: 550px) {
  .sidebar {
    position: relative;
  }
  .h2-cat {
    left: 26%;
    width: 300px;
    top: 80%;
  }
  .catpro {
    left: 70px;
    top: 80%;
  }
  .catpro:hover {
    top: 0%;
  }
  .linha1 {
    margin-left: 70px;
    width: 110%;
    margin-top: -7px;
  }
}
.order {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 70%;
  margin-left: 9%;
  top: 70%;
}
.order img {
  width: 280px !important;
  height: 280px !important;
  margin-right: 20px;
}
.order video {
  width: 280px;
  height: 280px;
  margin-right: 20px;
}
.order div {
  bottom: 17%;
  margin-left: 3%;
}

h3 {
  position: relative;
  font-size: 10px;
  margin-left: 10px;
  font-family: "Segoe UI";
  font-weight: 700;
  color: gray;
  bottom: 72%;
}

.a {
  position: relative;
  margin-bottom: 10px;
  text-decoration: none;
  color: #228B22;
  font-family: "Segoe UI";
  font-weight: 700;
  font-size: 16PX;
  bottom: 72%;
  cursor: pointer black;
  z-index: 9990;
}
.ahover {
  color: green;
}

@media (min-width: 270px) and (max-width: 550px) {
  .order {
    flex-direction: column;
    margin-left: 11%;
    position: relative;
    height: 100px;
  }
  .order div {
    bottom: 20%;
    margin-left: 1%;
    width: 280px;
    height: 280px;
    margin-bottom: 20px;
  }
  .order img {
    margin-bottom: 20px;
  }
  .order video {
    width: 280px;
    height: 280px;
    margin-right: 1px;
  }
  .order .flicking-viewport {
    top: 90px;
  }
  h3 {
    bottom: -6%;
  }
  .a {
    bottom: -3%;
  }
}
.espaco {
  height: 560px;
}

.produto-principal {
  width: 100%;
  height: 700px;
}

#barra {
  position: relative;
  left: 120px;
  color: white;
}

#barra1 {
  position: relative;
  left: 260px;
  bottom: 33px;
  color: white;
}

.imgp {
  position: relative;
  width: 70%;
  height: 680px;
  left: 80px;
  top: 10px;
}

.btn-slider {
  position: relative;
  display: flex;
  flex-direction: column;
  right: 100px;
  bottom: 670px;
  margin-bottom: 5px;
  border: none;
}
.btn-slider img {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 10px;
}
.btn-slider:hover {
  cursor: pointer;
}

.prod-h1 {
  position: relative;
  left: 310px;
  bottom: 690px;
  font-weight: 700;
  font-size: 35px;
  font-family: "Segoe UI";
  color: rgb(131, 128, 128);
}

#txt-cf5 {
  position: absolute;
  width: 36%;
  bottom: 30px;
  left: 750px;
  font-size: 20px;
  font-family: "Segoe UI";
  font-weight: 500;
}

label {
  position: relative;
  left: 300px;
  top: -350px;
  font-size: 20px;
}

select {
  position: relative;
  left: 330px;
  top: -350px;
  width: 330px;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.whatsapp {
  position: relative;
  height: 70px;
  width: 250px;
  left: 900px;
  top: -320px;
  border-radius: 10px;
  background-color: gray;
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
}
.whatsapp:hover {
  background-color: #228B22;
  box-shadow: none;
  border: 1px solid black;
}

.p-what {
  position: relative;
  bottom: 60px;
  left: 30px;
  color: white;
  font-family: "Segoe UI";
}

.what {
  text-decoration: none;
  color: gray;
  font-size: 25px;
  font-family: "Segoe UI";
}

.what img {
  position: relative;
  top: 12px;
  left: -90px;
  width: 45px;
  height: 45px;
}

.imgEspecial {
  position: relative;
  border: 1px solid lightgray;
  width: 700px;
  right: 200px;
}

@media (min-width: 270px) and (max-width: 550px) {
  html {
    overflow-x: hidden !important;
  }
  button {
    width: 82px;
  }
  .imgp {
    left: 5px;
    height: 500px;
    top: 31px;
  }
  .carousel div {
    width: 380px;
    height: 500px;
  }
  .btn-slider {
    display: flex;
    max-width: 82px;
    right: -1px;
    bottom: -50px;
    flex-direction: row-reverse;
    margin-left: 10px;
  }
  .btn-slider img {
    width: 70px !important;
    height: 70px !important;
  }
  .btn-2 {
    left: 90px;
    bottom: 27px;
  }
  .btn-3 {
    left: 180px;
    bottom: 105px;
  }
  .btn-4 {
    left: 270px;
    bottom: 180px;
  }
  .btn-6 {
    left: -20px;
  }
  .btn-7 {
    left: 55px;
    bottom: 27px;
  }
  .btn-8 {
    left: 130px;
    bottom: 105px;
  }
  .btn-9 {
    left: 205px;
    bottom: 182px;
  }
  .btn-10 {
    left: 280px;
    bottom: 259px;
  }
  .prod-h1 {
    left: 10px;
    font-size: 20px;
    bottom: -170px;
  }
  #txt-cf5 {
    position: relative;
    left: 30px;
    bottom: -150px;
    width: 330px;
  }
  label {
    left: -30px;
    top: 150px;
  }
  select {
    left: -10px;
    width: 120px;
    height: 30px;
    top: 150px;
  }
  .whatsapp {
    width: 200px;
    height: 50px;
    left: 100px;
    top: 190px;
  }
  .p-what {
    left: 20px;
    font-size: 18px;
    bottom: 45px;
  }
  .what img {
    width: 30px;
    height: 30px;
    left: -75px;
    top: 8px;
  }
  .imgEspecial {
    width: 100%;
    right: 0px;
    border: none;
  }
}
.produto-infor {
  position: relative;
  height: 600px;
  width: 100%;
  top: 240px;
}

.infad {
  position: relative;
  left: -430px;
  bottom: 39px;
  text-decoration: none;
  color: GRAY;
  font-family: "Segoe UI";
  font-weight: 700;
  border: none;
  background: white;
}

.linha1 {
  top: 82px;
  left: 50px;
}

.des {
  position: relative;
  left: -600px;
  top: -17px;
  text-decoration: none;
  color: gray;
  font-family: "Segoe UI";
  font-weight: 700;
  border: none;
  background: white;
}

.linha2 {
  display: none;
  position: relative;
  width: 90px;
  background-color: #228B22;
  height: 6px;
  left: 20px;
  bottom: 20px;
}

.linha3 {
  display: none;
  position: relative;
  width: 205px;
  background-color: #228B22;
  height: 6px;
  left: 140px;
  bottom: 45PX;
}

.pri1 {
  display: block;
}
.pri1:hover .linha2 {
  display: block;
}
.pri1:hover .des {
  color: black;
}

.pri2:hover {
  display: block;
}
.pri2:hover:hover .infad {
  color: black;
}
.pri2:hover:hover .linha3 {
  display: block;
}

.descricao1 {
  position: relative;
  margin-left: 40px;
  font-size: 20px;
  font-family: "Segoe UI";
  right: 370px;
}

.informacao {
  position: relative;
  margin-left: 40px;
  font-size: 20px;
  font-family: "Segoe UI";
  right: 600px;
}

.hidden {
  display: none;
}

li {
  list-style: none;
}

.linha4 {
  position: relative;
  width: 97%;
  background-color: whitesmoke;
  height: 2px;
  bottom: 17PX;
}

.linha5 {
  position: relative;
  width: 97%;
  background-color: whitesmoke;
  height: 2px;
  bottom: -27PX;
}

.linha6 {
  position: relative;
  width: 97%;
  background-color: whitesmoke;
  height: 2px;
  bottom: 35PX;
}

#compri {
  position: relative;
  left: 60px;
  font-size: 20px;
  font-family: "Segoe UI";
  font-weight: 700;
  top: 35px;
}

#cm {
  position: relative;
  left: 1000px;
  font-size: 20px;
  font-family: "Segoe UI";
  font-weight: 700;
  bottom: 13px;
}

@media (min-width: 270px) and (max-width: 550px) {
  body {
    overflow-x: hidden;
  }
  html {
    overflow-x: hidden !important;
  }
  .produto-infor {
    top: 500px;
    height: 200px;
  }
  .linha1 {
    left: -40px;
    width: 80%;
  }
  .linha3 {
    width: 110px;
    left: 90px;
    top: 58px;
    height: 6px;
  }
  .infad {
    left: -50px;
    top: 60px;
  }
  .des {
    left: -150px;
    top: 82px;
  }
  .linha2 {
    left: -7px;
    top: 80px;
  }
  .descricao1 {
    right: 10px;
    top: 50px;
  }
  .informacao {
    right: 180px;
    top: 20px;
  }
  #cm {
    left: 200px;
  }
}
html {
  overflow-x: hidden;
}

.container-form {
  position: relative;
  width: 1100px;
  height: 680px;
  border-radius: 20px;
  top: 20px;
  left: 100px;
  text-align: left;
  font-weight: 600;
  font-size: 30px;
  padding-left: 30px;
  background: green;
  margin-bottom: 100px !important;
}

.header-form h1 {
  position: relative;
  text-align: center;
  color: white;
  font-size: 40PX;
  bottom: 580px;
  left: 200px;
}

.loop-form {
  position: relative;
  top: 60px;
  width: 400px;
  height: 600px;
  border-radius: 20px;
}

.imgs-loop {
  width: 400px;
  height: 600px;
  border-radius: 20px;
  border: 3px solid #228B22;
}

.divs-form {
  position: relative;
  bottom: 650px;
  left: 430px;
  width: 640px;
  height: 600px;
  border: 1px solid white;
  border-radius: 20px;
}
.divs-form div {
  position: relative;
  top: 380px;
  right: 280px;
}
.divs-form div label {
  font-size: 35px;
  color: white;
}
.divs-form div select {
  width: 200px;
  padding-left: 50px;
  background: #228B22;
  border: 1px solid white;
  color: white;
  font-weight: 600;
}
.divs-form div select option {
  width: 200px;
  padding-left: 50px;
}
.divs-form div .div-form1 {
  padding-left: 40px;
}
.divs-form div .div-form2 {
  padding-left: 65px;
}
.divs-form div .div-form3 {
  padding-left: 70px;
}
.divs-form div input {
  position: relative;
  bottom: 360px;
  left: 310px;
  width: 180PX;
  height: 30PX;
  border-radius: 20PX;
  border: none;
  padding-left: 10px;
}

.enviar {
  width: 220px;
  height: 50px;
  position: relative;
  border: 3px solid white;
  border-radius: 20px;
  bottom: 104%;
  left: 76%;
  background: green;
  color: white;
}
.enviar span {
  position: relative;
  font-size: 30px;
}
.enviar:hover {
  cursor: pointer;
  background: #228B22;
}

.mensagem {
  position: absolute;
  bottom: 4%;
  left: 43%;
  width: 250px;
  height: 100px;
  border: 1px solid white;
  border-radius: 20px;
}
.mensagem span {
  position: relative;
  left: 10px;
  font-size: 23px;
  width: 200px;
}

@media (min-width: 270px) and (max-width: 550px) {
  .container-form {
    top: 140px;
    width: 380px;
    left: 0px;
    height: 2100px;
  }
  .header-form h1 {
    bottom: 620px;
    font-size: 25px;
    left: -10px;
  }
  .loop-form {
    width: 300px;
    right: 30px;
    top: 1440px;
  }
  .imgs-loop {
    width: 375px;
  }
  .divs-form {
    bottom: 1070px;
  }
  .divs-form div {
    right: 420px;
    margin-bottom: 40px;
  }
  .divs-form div label {
    font-size: 28px;
    left: 10px;
  }
  .divs-form div select {
    width: 200px;
    position: relative;
    top: 170px;
    display: flex;
    justify-content: flex-start;
    z-index: 9999;
    left: 20px;
  }
  .divs-form div select option {
    width: 100px;
  }
  .divs-form div input {
    bottom: -170px;
    left: 20px;
    display: flex;
    justify-content: flex-start;
    z-index: 9999;
  }
  .mensagem {
    margin-top: 40px;
    left: 10%;
    bottom: 39%;
  }
  .enviar {
    bottom: -4%;
    left: 14%;
  }
}
body {
  overflow-x: hidden !important;
}

