@import './variavel.scss';
.rodape{
	position: relative;
	height: 100px;
	background: $color-primary;
	bottom: 0px;
	margin-bottom: 0%;
	.p-rod{
	
		position: relative;
		top: 40%;
		display: flex;
		justify-content: center;
		color: white;
		font-family: Arial, Helvetica, sans-serif;
		
	}
}
@media (min-width:270px) and  (max-width: 550px){
.rodape{
	top: 620px;
}
}	

