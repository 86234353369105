@import '../principal/variavel.scss';
html{
    overflow-x: hidden;
}

.container-form{
    position: relative;
    width: 1100px;
    height: 680px;
    border-radius: 20px;
    top: 20px;
    left:100px;
    text-align: left ;
    font-weight: 600;
    font-size: 30px;
    padding-left: 30px;
   background:  $color-primary;
   margin-bottom: 100px !important;
 
}
.header-form{
    h1{
        position: relative;
        text-align: center;
        color: white;
        font-size: 40PX;
        bottom: 580px;
        left: 200px;
       }
}
.loop-form{
    position: relative;
    top: 60px;
    width: 400px;
    height: 600px;
    border-radius: 20px;
}
.imgs-loop{
 width: 400px;
 height: 600px;
 border-radius: 20px;  
 border: 3px solid $color-secondary;
}
.divs-form{
    position: relative;
    bottom: 650px;
    left: 430px;
    width: 640px;
    height: 600px;
    border: 1px solid white;
    border-radius: 20px;
    div{
        position: relative;
        top:380px;
        right: 280px;
        
        label{
            font-size: 35px;
            color: white;
        }
        select{
            width: 200px;
            padding-left: 50px;
            background: $color-secondary;
            border: 1px solid white;
            color: white;
            font-weight: 600;
            option{
                width: 200px;
                padding-left: 50px;
            }
        }
        .div-form1{
            padding-left: 40px;
        }
        .div-form2{
            padding-left: 65px;
        }
        .div-form3{
            padding-left: 70px;
        }
        input{
            position: relative;
            bottom:360px;
            left: 310px;
            width: 180PX;
            height: 30PX;
            border-radius: 20PX;
            border: none;
            padding-left: 10px;

        }
    }
    
}
.enviar{
    width: 220px;
    height: 50px;
    position: relative;
    border: 3px solid white;
    border-radius: 20px;
    bottom:104%;
    left: 76%;
    background: $color-primary;
    color: white;
    span{
        position: relative;
        font-size: 30px;
        
    }
    &:hover{
        cursor: pointer;
        background: $color-secondary;
    }
}
.mensagem{
    position: absolute;
    bottom: 4%;
    left: 43%;
    width: 250px;
    height: 100px;
    border: 1px solid white;
    border-radius: 20px;
    span{
        position: relative;
        left: 10px;
        font-size: 23px;
        width: 200px ;
    }
}
@media (min-width:270px) and  (max-width: 550px){
    .container-form{
        top: 140px;
        width: 380px;
        left: 0px;
        height: 2100px;
    }
    .header-form{
        h1{
            bottom: 620px;
            font-size: 25px;
            left: -10px;
        }
}
.loop-form{
    width: 300px;
    right: 30px;
    top: 1440px;
}
.imgs-loop{
    width: 375px;
}
.divs-form{
    bottom: 1070px;
    div{
        right: 420px;
        margin-bottom: 40px;
        label{
            font-size: 28px;
            left: 10px;
        }
        select{
            width: 200px;
            position: relative;
            top: 170px;
            display: flex;
            justify-content: flex-start;
            z-index: 9999;
            left: 20px;
            option{
                width: 100px;
            }
        }
        input{
            bottom: -170px;
            left: 20px;
            display: flex;
            justify-content: flex-start;
            z-index: 9999;
        }
    }
}
.mensagem{
    margin-top: 40px;
    left: 10%;
    bottom: 39%;
}
.enviar{
    bottom: -4%;
    left: 14%;
}
}