@import '../principal/variavel.scss';

.catalogo{
    z-index: 10;
	position: relative;
	width: 100%;
	height: 100px;
	background: $color-primary;
	top: 10px ;
}
.cat-ini{
	position: relative;
	color: white;
	margin-left: 40px;
	top: 30px;
	font-weight: 700;
	font-size: 20px;
	font-family: "Segoe UI";
	text-decoration: none;
	right: 400px;
}
.cat-cat{
	position: relative;
	color: white;
	margin-left: 80px;
	top: 30px;
	left: -440px;
	font-weight: 700;
	font-size: 20px;
	font-family: "Segoe UI";
	text-decoration: none;
	
}
@media (min-width:270px) and  (max-width: 550px){
.catalogo{
	top: 90px;
}
	.cat-ini{
	right:70px;
	font-size: 13px;
	top: 40px;
}
.cat-cat{
	left: -140px;
	font-size: 13px;
	top: 40px;
}

}