
.sobrenos{
	position: relative;
	top: 15px;
	display: flex;
	flex-direction: column;
	background: black;
	height: 520px;
    width: 100% !important;
}


.titleS{
	color: white;
}
.btn-fim{
	position: relative;
	left: 80px;
	bottom: 60px;
	font-size: 20px;
	color: white;
	border: none;
	background: black;
	
}

.imgs-fim{
	position: relative;
	top:80% ;
	width: 60px;
	height: 60px;
}
.icons-fim{
	width: 20px;
	height: 20px;
}
.fim-one{
	position: relative;
	width: 300px;
	height: 100px;
	left: 55%;
	bottom: 570px;
}
.fim-two{
	position: relative;
	width: 300px;
	height: 100px;
	left: 55%;
	bottom: 550px;
}
.fim-three{
	position: relative;
	width: 300px;
	height: 100px;
	left: 55%;
	bottom:530px;
}
.fim-four{
	position: relative;
	width: 300px;
	height: 100px;
	left: 55%;
	bottom:510px;
}
.gvtnine{
	position: absolute;
	left: 60%;
	width: 100%;
	top:100%;
	color: white;
	font-family: "Segoe UI";
	font-weight: 700;
	cursor: pointer;
	color: white;
	text-decoration: none;
}
.gvtseven{
	position: absolute;
	left: 60%;
	width: 100%;
	top:100%;
	color: white;
	font-family: "Segoe UI";
	font-weight: 700;
		cursor: pointer;
		color: white;
		text-decoration: none;
}

.gvtthree{
  position: absolute;
	left: 60%;
	width: 100%;
	top:100%;
	color: white;
	font-family: "Segoe UI";
	font-weight: 700;
	cursor: pointer;
	color: white;
	text-decoration: none;
}

.of-btn {
	position: absolute;
	left: 60%;
	width: 100%;
	top:100%;
	color: white;
	font-family: "Segoe UI";
	font-weight: 700;
	cursor: pointer;
	color: white;
	text-decoration: none;
}

.titleS2{
	position: relative;
	height: 50px;
	color: white;
	font-size: 20px;
	left: 25%;
	bottom:  530px;
	font-size: 25px;
	
}
.titleS1{
	position: relative;
	height: 50px;
	color: white;
	font-size: 20px;
	top: 40px;
	right: 32%;
	margin-left: 22%;
	font-size: 25px;
}
.pS{
	margin-top:  70px;
	position: relative;
	width: 50%;
	margin-left: 20px;
	color: white;
	font-size: 20px;
	text-align: justify;
}
.icon-fim{
	position: relative;
	max-width: 32px;
	max-height: 32px;
}
.fac{
	left: 40px;
	background: white;
	border: 1px solid white;
	border-radius: 100px;
	top: 8px;
		background-color: #3b5998;

}
.ins{
	left: 100px;
	bottom: 28px;
	background-color:white;
	border-radius: 100px;
	background: linear-gradient(45deg,#405DE6,#5851DB,#833AB4,#C13584,
	#E1306C,#FD1D1D);
	border: 1px solid white;
}
.tik{
	left: 160px;
	bottom: 65px;
	background-color: white;
	border-radius: 50%;
    border: 1px solid white;
}

.iconS{
	position: relative;
	width: 40px;
	height: 40px;
}
@media (min-width:270px) and  (max-width: 550px){
.sobrenos{
	top: 650px;
	height: 1100px;
}
.titleS{
	left: -40px;
	top: -10px;
}

.pS{
	width: 330px;
	bottom: 100px;
	text-align: justify;
	left: 10px;
}
.iconS{
	bottom: 120px;
	left: 60px;
}
.ins{
	bottom: 28px;
}
.tik{
	bottom: 65px;
}
.titleS1{
	left: -50px;
}
.titleS2{
	position: relative;
	top: -200px;
	left: -5px;
}
.fim-one{
	top: -230px;
	margin-bottom: 20px;
	left: -25%;
}
.fim-two{
	top: -230px;
	margin-bottom: 20px;
	left: -25%;
}
.fim-three{
	top: -230px;
	margin-bottom: 20px;
	left: -25%;
}
.fim-four{
	top: -230px;
	margin-bottom: 20px;
	left: -25%;
}
}