@import '../principal/variavel.scss';
.order{
	display: flex;
	flex-direction: row;
	position: absolute;
	width: 70%;
	margin-left: 9%;
	top: 70%;
	img{
        width: 280px !important;
        height: 280px !important;
        margin-right: 20px;
    }
    video{
        width: 280px;
        height: 280px;
        margin-right: 20px;
    }
    div{
        bottom: 17%;
        margin-left: 3%;
    }
}
h3{
    position: relative;
	font-size: 10px;
	margin-left: 10px;
	font-family: "Segoe UI";
	font-weight: 700;
	color: gray;
    bottom: 72%;
}
.a{
    position: relative;
	margin-bottom: 10px;
	text-decoration: none;
	 color: $color-secondary;
	 font-family: "Segoe UI";
	 font-weight: 700;
     font-size: 16PX;
	 &hover{
        color: $color-primary;
    }
    bottom: 72%;
    cursor: pointer black;
    z-index: 9990;
}
@media (min-width:270px) and  (max-width: 550px){
    .order{
        flex-direction: column;
        margin-left: 11%;
        position: relative;
        height: 100px;
        div{
            bottom: 20%;
            margin-left: 1%;
        width: 280px;
        height: 280px;
        margin-bottom: 20px;
        }
        img{
            margin-bottom: 20px;
        }
        video{
            width: 280px;
            height: 280px;
            margin-right: 1px;
        }
        .flicking-viewport{
            top: 90px;
        }
    }
    h3{
       bottom: -6%;
    }
    .a{

        bottom: -3%;
    }
}