.whatsNovo{
    position: fixed;
    left: 90%;
    bottom: 100px;
    width: 90px;
    height: 90px;
    border-radius: 60%;
}
@media (min-width:270px) and  (max-width: 550px){
 .whatsNovo{
    width: 50px;
    height: 50px;
    left: 80%;
    top: 56%;
    z-index: 99999;
 }
}