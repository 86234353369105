.mySlides{
position: relative;
  top: 20px;
 margin: auto;
 width: 100%;
 left: 1px;
 &:hover {
  cursor: pointer;
}
}
.carousel-inner div{
  width: 100%;
}
@media (min-width:270px) and  (max-width: 550px){
.mySlides{
  top: 80px;
  height: 300px;
}
}