@import './variavel.scss';
.imgProduct{
    width: 410px;
    height: 390px;
}
.card-btn{
    width: 250px;
    height: 50px;
    top: 130px;
}
.flicking-panel{
    width: 250px;
    height: 450px;
    margin-left: 10px;
    div img{
        width: 250px;
        height: 250px;
    }
}
.container{
    position: relative;
    top: 10px;
    margin-bottom: 100px;
    left: 15%;
    width: 100%;
    height: 400px;
}
.slick-dots{
    position: relative;
    top: 500px;
}
.title-special{
    width: 250px;
    height: 250px;
    border: 1px solid black;
}
.btn-more-products {
	box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
	
	padding:10px;
	width: 150px;
	text-transform: capitalize;
	border: none;
	border-radius: 5px;
	transition: 0.5s;
	cursor: pointer;
	font-size: 20px;
	background: $color-primary;
	color: white;
    text-decoration: none;
    position: relative;
    bottom: 50px;
    left: 30px;
   &:hover{
    bottom: 60px;
	font-size: 25px;
	outline: none;
	box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
   }
   &:active{
    box-shadow: none;
    border: 1px solid black;
   }
  }
  @media (min-width:270px) and  (max-width: 750px){
    .flicking-panel{
        height: 500px;
    }
  .imgProduct{
    width: 370px;
    height: 470px;
    position: relative;
    right: 50px;
  }
  .btn-more-products{
    left: 0px;
  }
}
  