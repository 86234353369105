@import './principal/header.scss';
@import './principal/variavel.scss';
@import './principal/banner.scss';
@import './principal/slider.scss';
@import './principal/produtocampeao.scss';
@import './principal/depoimento.scss';
@import './principal/caracteristicas.scss'; 
@import './principal/sobrenos.scss';
@import './principal/rodape.scss';
@import './principal/whatsapp.scss';
@import './nossoproduto/navegacao.scss';
@import './nossoproduto/sidebar.scss';
@import './nossoproduto/produtos.scss';
@import './nossoproduto/espaco.scss';
@import './Produtos/produtoprincipal.scss';
@import './Produtos/produtoinfo.scss';
@import './PersonalizarProduto/Etapa.scss';


body{
    overflow-x: hidden !important;
}