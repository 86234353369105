@import './variavel.scss';
.header-princip{
	width: 100%;
	position: relative;
	height: 90px;
    top: 110px;
}
.divulgacao{
	position: relative;
	width: 100%;
	height: 40px;
	background: $color-primary;
	bottom:150%;
}
#pd{
	position: relative;
	top: 12px;
	color: $color-primary;
	margin-left: 20px;
	font-weight: 700;
}
.icon-fac{
	position: relative;
	width: 27px;
	height: 27px;
	left: 48% ;
	bottom: 30px;
	border: white 2px solid;
	border-radius: 15px;
	background: white;
	background-color: #3b5998;
	&:hover{
	width: 30px;
	height: 30px;
	}
}

.icon-tik{
	position: absolute;
	width: 27px;
	height: 27px;
	left: 89% ;
	bottom: 2px;
	border: white 2px solid;
	border-radius: 15px;
	background: white;

	&:hover{
		width: 30px;
		height: 30px;
	}
}

.icon-ins{
	position: absolute;
	width: 27px;
	height: 27px;
	left: 93% ;
	bottom: 2px;
	border: white 2px solid;
	border-radius: 15px;
	background: white;
	background: linear-gradient(45deg,#405DE6,#5851DB,#833AB4,#C13584,
	#E1306C,#FD1D1D);
	&:hover{
  width: 30px;
  height: 30px;
	}
}

/* header */

#header{
	margin-top: -1px;

    position: relative;
    width: 100%;
    height: 30px;
    background: white;
	height: 60px;
	bottom: 40px;
	
}
#imgIcon {
    width: 30px;
    height: 30px;
    margin-left: 2%;
    border-radius: none;
    position: absolute;
   
}

/*Pesquisa */
#Hpes img{
	position: relative;
	left: 32%;
	width: 25px;
	height: 25px;
	top: -72px;
	
}
#HprodF{
	position: relative;
	left: 32%;
	font-weight: 700;
	font-size: 15px;
	top: -62px;
		color: black;
}
#HprodP{
	position: relative;
	left: 32%;
	font-weight: 700;
	font-size: 15px;
	top: -80px;
		color: black;
}
#Hprod{
	position: relative;
	left: 31%;
	font-weight: 700;
	font-size: 15px;
	top: -80px;
		color: black;
}
#Home{
	position: relative;
	left: 30%;
	font-weight: 700;
	font-size: 15px;
	top: -80px;
	color: black;
}
#IconEmpresa {
	width: 180px;
    height: 60px;
    margin-left: 3%;
    top: -80px;
    left: -25px;
    position: absolute ;
    -moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
    
}

.pesq{
	z-index: 999;
	display: none;
	position: relative;
	left: 72%;
	width: 350px;
	top: -50px;
	height: 70px;
	background: white;
	box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.3);
	.img-pesq{
		width: 30px;
		height: 36px;
	}
	.btn-pesq{
		width: 45px;
		height: 44px;
		position: relative;
		top: 15px;
		left: 11px;
		border: 3px solid black;
		background: whitesmoke;
	}
	
	.ipt-pesq{
		position: relative;
		width: 270px;
		left: 15px;
		height: 40px;
		top: 3px;
		border: 1px solid gray;
	}
	&:active{
		border: none;
	}
}




.miniproduto{
    height: 100%;
}
.c{
	z-index: 990;
	display: none;
 height: 50px;
 position: relative;
	left: 72%;
	width: 350px;
	bottom: 37%;
	background: white;
	img{
		position: absolute;
		width: 50px;
		height: 50px;
		border-radius: 30px;
		left: 30px;
	}
	a{
		position: absolute;
		top: 10px;
		left: 90px;
		color: black;
		font-family: "Segoe UI";
		text-decoration: none;
	}
}
.miniproduto{
	.c .b strong {
		font-weight: bold;
	  }
     a:hover{
	  text-decoration: none;
	  color: black;
	  
	}
} 
@media (min-width:270px) and  (max-width: 550px){
	html{
		overflow-x: hidden;
	}
	.header{
		height: 110px;
	}
	.divulgacao{
		height: 70px;
	}
	#pd{
		width: 200px;
	font-size: 10px;
	right: 20px;
	top: 20px;
    }
	.icon-fac{
		left: 20%;
		bottom: 22px;
	}
	.icon-tik{
		left: 76%;
		bottom: 10px;
	}
	.icon-ins{
		left: 85%;
		bottom: 10px;
	}
	#IconEmpresa {
		width: 190px;
		height: 130px;
		left: -3px;
		bottom: 15px;
		z-index: 9968;
	}
	#Home{
		left: 43%;
		top: -85px;
		font-size: 15px;
		z-index: 9999;
	}
	#Hprod{
		left: 31%;
		top: -61px;
		font-size: 15px;
		z-index: 9997;
	}
	#HprodP{
		left: -5%;
		top: -34px;
		font-size: 15px;
		z-index: 9996;
	}
	.slide{
		bottom: -40px !important;
	}
	#HprodF{
		left: 1%;
		top: -30px;
		img{
			width: 50px;
			height: 50px;
		}
		z-index: 9999;
	}
	#Hpes img{
		left: 20%;
		top: -40px;
		width: 30px;
			height: 30px;
	}
	.pesq{
		top: -30px;
		width: 270px;
		left: 20%;
	}
	.ipt-pesq{
		width: 200px !important;
		left: 10px !important;
		top: -2px !important;
	}
	.btn-pesq{
		left: 2px !important;
		top: 12px !important;
	}
	.c{
		width: 270px;
		left: 20%;
		height: 70px;
		border: 1px solid lightgray;
		bottom: 19%;
		img{
			width: 35px;
			height: 35px;
			left: 10px;
			top: 10px;
		}
		a{
			left: 60px;
		}
	}
}