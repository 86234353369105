@import '../principal/variavel.scss';
.sidebar{
	height: 100px;
	width: 40%;
	position: absolute;
	top: 40%;
	
}
.h2-cat{
	position: relative;
	color: black;
	left: -24%;
	top: -1%;
	font-weight: 700;
	font-size: 20px;
	font-family: "Segoe UI";
	text-decoration: none;
}
.catpro{
	display: flex;
	flex-direction: column;
	position: relative;
	left: 30px;
	width: 230px;
	margin-top: 10px;
	top:2%;
	text-decoration: none;
	color: $color-secondary;
	font-size: 20px;
	font-family: "Segoe UI";
	font-weight: 700;
    &:hover{
        color: $color-primary;
        font-size: 25px;
        width:  300px;
		top: -1%;
    }
	
}


.linha1{
	position:absolute;
    width: 40%;
    height: 2px;
    background-color: lightgray;
    top: 17%;
    left: 6%;
	margin-top: 10px;
}
@media (min-width:270px) and  (max-width: 550px){
	.sidebar{
		position: relative;
	}
	.h2-cat{
	left: 26%;
	width: 300px;
	top: 80%;
	}
	.catpro{
		left: 70px;
		top: 80%;
		&:hover{
			top: 0%;
		}
	}
	.linha1{
		margin-left: 70px;
		width: 110%;
		margin-top: -7px;
	}
}